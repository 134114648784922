<template>
  <div class="friends-options pt-2 px-0 team-details-tab pb-0">
    <nav class="navbar-expand-lg">
      <div class="navbar-nav mx-auto">
        <ul class="mb-0">
          <router-link
            class="nav-link pt-0 pb-1"
            :to="{ name: 'AssociationEvents', params: { associationGuid: $route.params.associationGuid }}"
          >
            <span>Events</span>
          </router-link>
          <router-link
            class="nav-link pt-0 pb-2"
            :to="{ name: 'AssociationUsers', params: { associationGuid: $route.params.associationGuid }}"
          >
            <span>Users</span>
          </router-link>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "AssociationTab",
  methods: {
    extractAssociationGuidFromUrl() {
      const url = window.location.pathname;
      const parts = url.split('/');
      const associationGuid = parts[parts.length - 1]; // Assuming associationGuid is the last part of the URL
      return associationGuid;
    }
  },
  mounted() {
     this.extractAssociationGuidFromUrl();
  },
};
</script>

<style scoped>
.navbar-expand-lg .navbar-nav .active {
  color: #188ef5 !important;
  border-bottom: 4px solid #188ef5;
}
.navbar-expand-lg .navbar-nav .nav-link {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  padding: 19px 10px;
  display: flex;
  margin: 0 !important;
  margin-right: 5px !important;
  align-items: center;
  position: relative;
  color: #557491 !important;
}
</style>
