<template>
    <div>
      <button
        class="d-none"
        data-toggle="modal"
        data-target="#UpdateUserInvitation"
        ref="openUpdateInviteModal"
        @click="dataReset()"
      ></button>
      <div class="modal fade" tabindex="-1" role="dialog" id="UpdateUserInvitation">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title m-0">Update user role for {{ associationData.association_name }} - Association Portal</h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                ref="closeUpdateInviteuserAssociationModal"
              >
                <img src="../../../assets/close.png" />
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                  <div class="input-wrapper">
                    <input
                      id="email"
                      class="form-control disabled"
                      v-model.trim="$v.details.email.$model"
                      spellcheck="false"
                      autocomplete="off"
                      :class="{
                        'is-invalid': validationStatus($v.details.email),
                      }"
                      trim
                      required
                      type="text"
                      v-on:keyup="errorNull"
                    />
  
                    <span class="placeholder">Email</span>
                    <div v-if="!$v.details.email.email" class="invalid-tooltip">
                      Please provide valid email
                    </div>
                  </div>
                </div>
              <div class="form-group">
                <select class="form-control" id="role" v-model="selectedRole" :disabled="isLoadingArray[0]" @change="errorNull">
                  <option value="">Select Role</option>
                  <option value="User">Admin</option>
                  <option value="Umpire">Umpire</option>
                </select>
              </div>
            </div>
            <div class="event-btns-wrapper p-3 justify-content-end">
              <button
                type="button"
                class="btn btn-primary w-100"
                block
                @click="updateInvites(0)"
                :disabled="isLoadingArray[0] || isDisabled"
              >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else>Update Role</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { email } from "vuelidate/lib/validators";
  
  export default {
    name: "UpdateRoleModal",
    data() {
      return {
        userInvites: {},
        isDisabled: true,
        isLoadingArray: [],
        email: "",
        selectedRole: "",
        errorMessage: null,
        errorMessageEmail: null,
        successMessage: null,
        details: {
          email: "",
        },
      };
    },
    props: {
      associationData: {
        type: Object,
      },
    },
    validations: {
      details: {
        email: { email },
      },
    },
   
methods: {
  validationStatus: function (validation) {
    return typeof validation != "undefined" ? validation.$error : false;
  },
  errorNull: function () {
    // this.errorMessage = null;
    this.errorMessageEmail = null;
    if (
      (this.details.email == "" || this.selectedRole == "" ) ||
      this.$v.$invalid
    ) {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
  },
  dataReset: function () {
    this.isDisabled = true;
    this.details.email = this.userInvites.target || "";
    this.selectedRole = this.userInvites.level === "3" ? "User" : "Umpire";
  },
  
  updateInvites(index) {
    this.isDisabled = true;
    this.$v.$touch();
    if (this.$v.$pending || this.$v.$error) return;
    this.$set(this.isLoadingArray, index, true);
    let level = this.selectedRole === 'User' ? 3 : 4;
    
    let formData = new FormData();
    formData.append('invite_id', this.userInvites.id);
    formData.append('new_level', level);
    
    axios.post(process.env.VUE_APP_API_URL + 'association/updateInviteUserRole', formData)
        .then(response => {
          if (response.data.statusCode === 200) {
             console.log("Role update successfully", response);
              setTimeout(() => {
                  this.$set(this.isLoadingArray, index, false);
              }, 1000);
              this.$root.$emit("userAssociationAction");
              var elem = this.$refs.closeUpdateInviteuserAssociationModal;
              if (elem) {
                  elem.click();
              }
            } else if (response.data.statusCode === 404) {
              this.$alert(response.data.message,  'Error');
              {
                this.$root.$emit("userAssociationAction");
                  var elem1 = this.$refs.closeUpdateInviteuserAssociationModal;
                  if (elem1) {
                      elem1.click();
                  }
              }              
          }
           
        })
        .catch(error => {
            console.error("Error sending invitation", error);
        })
        .finally(() => {
            this.isLoadingArray[index] = false;
            this.dataReset();
        });
    this.$set(this.isLoadingArray, index, true);
    },
},
  mounted() {
    this.$root.$on("updateUsersInviteModal", (data) => {
        this.userInvites = data;
        this.dataReset();
        var elem = this.$refs.openUpdateInviteModal;
        if (elem) {
        elem.click();
        } 
      });
        },
    };
</script>
<style>
  .sub-setting-options {
      top: -173%;
      right: 28px;
  }
</style>