<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3">

        <div class="order-history single-box-score-loader" v-if="showLoader == 1" >
              <div class="lds-dual-ring-media"></div>
        </div>

        <div class="bg-white p-3 mb-3 rounded" v-else-if="showLoader == 0 && !invalidAssociation">
          <AssociationDetail
            :associationData="associationData"
            :showLoader="showLoader"
          />
          <AssociationTab />
          <AssociationUsersDetail
            :associationData="associationData"
            :currentIsAdmin="currentIsAdmin"
            :currentIsUmpire="currentIsUmpire"
            :users="users"
            :lastPage="lastPage"
            :page="page"
            :showLoader="showLoader"
            :showLoader1="showLoader1"
            @page-increment="handlePageIncrement"
          />
        </div>

        <div v-else>
          <div class="card-event col-12 no-card-event bg-white">
                <div class="img">
                  <img src="../assets/images/games.png" alt="" />
                </div>
                <p class="text">No user association found.</p>
              </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import AssociationDetail from "../components/Association/associationDetail.vue";
import AssociationTab from "../components/Association/associationTab.vue";
import AssociationUsersDetail from "../components/Association/AssociationUsersDetail.vue";
import axios from "axios";

export default {
  name: "AssociationUsers",
  data() {
    return {
      invalidAssociation: false,
      associationData: {},
      showLoader: 1,
      currentIsAdmin: "0",
      currentIsUmpire:"0",
      showLoader1: false,
      users: [],
      lastPage: 1,
      page: 1,
    };
  },
  components: {
    Navbar,
    LeftMenu,
    Banner,
    AssociationDetail,
    AssociationTab,
    AssociationUsersDetail,
  },
  methods: {
    handlePageIncrement() {
      this.page++; // Update the page prop
      this.fetchUsers();
    },
    async fetchUsers() {
      try {
        if(this.users.length == 0){
          this.showLoader = 1;
        }
        this.showLoader1 = true;
        this.isLoading = true;

        var url = window.location.pathname;
        var segment_array = url.split("/");
        const Guid = segment_array.pop();

        const formData = new FormData();
        formData.append("association_guid", Guid);

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}association/associationUsersListing?page=${this.page}`,
          formData
        );
        if (response.data.statusCode === 200) {
          this.associationData = response.data.data.association;
           this.currentIsAdmin=(response.data.data.user_association_role)?(response.data.data.user_association_role.role_id==1)?'1':'0':'0';
          this.currentIsUmpire=(response.data.data.user_association_role)?(response.data.data.user_association_role.role_id==2)?'1':'0':'0';
          const newUsers = response.data.data.users.data;
          if (this.page === 1) {
            this.users = [];
          }
          this.users = this.users.concat(newUsers);
          this.total = response.data.data.total;
          this.lastPage = response.data.data.users.last_page;
          this.showLoader = 0;
          this.showLoader1 = false;
          this.isLoading = false;
        } else if (response.data.statusCode === 400){
          this.invalidAssociation =  true;
          this.showLoader = 0;
        } else {
          console.error("Error:", response.data.message || "Unknown error occurred");
          this.errorMessage = response.data.message || "Unknown error occurred";
        }
      } catch (error) {
        console.error("Error fetching user listing:", error);
        this.showLoader = 0;
        this.showLoader1 = false;
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.fetchUsers();
    this.$root.$on("page-increment", () => {
      this.handlePageIncrement();
    });
    this.$root.$on("userAssociationAction", () => {
      this.users = [];
      this.page = 1;
      this.fetchUsers();
    });
  },
};
</script>
