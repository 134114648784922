<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#UserInvitationS"
      ref="openModalInvite"
      @click="dataReset()"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="UserInvitationS">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Invite user for {{ associationData.association_name }} - Association Portal</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalInviteuserAssociation"
            >
              <img src="../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
                <div class="input-wrapper">
                  <input
                    id="email"
                    class="form-control"
                    v-model.trim="$v.details.email.$model"
                    spellcheck="false"
                    autocomplete="off"
                    :class="{
                      'is-invalid': validationStatus($v.details.email),
                    }"
                    trim
                    required
                    type="text"
                    v-on:keyup="errorNull"
                  />

                  <span class="placeholder">Email</span>
                  <div v-if="!$v.details.email.email" class="invalid-tooltip">
                    Please provide valid email
                  </div>
                </div>
              </div>
            <div class="form-group">
              <select class="form-control" id="role" v-model="selectedRole" :disabled="isLoadingArray[0]" @change="errorNull">
                <option value="">Select Role</option>
                <option value="User">Admin</option>
                <option value="Umpire">Umpire</option>
              </select>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              type="button"
              class="btn btn-primary w-100"
              block
              @click="sendInvites(0)"
              :disabled="isLoadingArray[0] || isDisabled"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Send Invite</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { email } from "vuelidate/lib/validators";

export default {
  name: "UserInviteModal",
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      email: "",
      selectedRole: "",
      errorMessage: null,
      errorMessageEmail: null,
      successMessage: null,
      details: {
        email: "",
      },
    };
  },
  props: {
    associationData: {
      type: Object,
    },
  },
  validations: {
    details: {
      email: { email },
    },
  },
 
  methods: {
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    errorNull: function () {
      // this.errorMessage = null;
      this.errorMessageEmail = null;
      if (
        (this.details.email == "" || this.selectedRole == "") ||
        this.$v.$invalid
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    dataReset: function () {
      this.isDisabled = true;
      this.details.email = "";
      this.selectedRole = "";
    },

    sendInvites(index) {
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      let level = this.selectedRole === 'User' ? 3 : 4;

      let inviteData = {
        invite_type: 7,
        level: level,
        association_id: this.associationData.id,
        users: [{
          email: this.details.email
        }]
      };
      axios.post(process.env.VUE_APP_API_URL + 'association/inviteUsers', {
        inviteData: inviteData
      })
        .then(response => {
          if (response.data.statusCode === 200) {
            setTimeout(() => {
              this.$set(this.isLoadingArray, index, false);
            }, 1000);
            this.$root.$emit("userAssociationAction");
            this.closeModal();

          } else if (response.data.statusCode === 404) {
            this.$alert(response.data.message);
            this.closeModal();

          }
        })
        .catch(error => {
          console.error("Error sending invitation", error);
          alert("An error occurred while sending the invitation.");
          this.closeModal();
        })
        .finally(() => {
          this.isLoadingArray[index] = false;
          this.closeModal();
          this.dataReset();
        });
      this.$set(this.isLoadingArray, index, true);
    },
    closeModal() {
      var elem = this.$refs.closeModalInviteuserAssociation;
      if (elem) {
        elem.click();
      }
    }
  },
  mounted() {
    this.$root.$on("usersInviteModal", () => {
      var elem = this.$refs.openModalInvite;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>