<template>
  <div>
    <UserInviteModal :associationData="associationData" />
    <UpdateRoleModal :associationData="associationData" />
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary" @click="openUserInviteModal"  v-if="currentIsAdmin == '1'">
        Invite User
      </button>
    </div>
    <b-row class="no-gutters" v-if="users.length > 0">
      <div class="col-12 products-column">
        <div class="event-card">
          <div class="table-responsive mb-0">
            <b-table
              :items="users"
              :fields="fields"
              hover
              responsive
              class="mt-1 b-datatable cursor-pointer"
            >
              <!-- Custom template for Status column -->
              <template v-slot:cell(status)="data">
                <div v-if="data.item.status == 0" class="accept-btn">
                  Accepted
                </div>
                <div class="pending-btn" v-else>
                  Pending
                </div>
              </template>

              <template v-slot:cell(name)="data">
                <div>
                  <template v-if="data.item.user_linked && data.item.user_linked.name">
                    {{ data.item.user_linked.name }}
                  </template>
                  <template v-else-if="data.item.user_profile">
                    {{ data.item.user_profile.fname || '' }} {{ data.item.user_profile.lname || '' }}
                  </template>
                  <template v-else>
                    {{ data.item.fname || '' }} {{ data.item.lname || '' }} 
                  </template>
                  <template v-if="!data.item.user_linked && !data.item.user_profile && !data.item.fname && !data.item.lname">
                    N/A
                  </template>
                </div>
              </template>

              <!-- Custom template for Role column -->
              <template v-slot:cell(role)="data">
                <div>
                  {{ data.item.level == 3 ? "Admin" : "Umpire" }}
                </div>
              </template>

              <!-- Custom template for Action column -->
              <template v-slot:cell(action)="data">
                <div class="content action-column d-flex justify-content-end" v-if="data.item.status == 1 && currentIsAdmin == '1'">
                  <button class="btn invite-btn p-0 pr-3" @click="cancelInvite(data.item)">
                    Cancel Invite
                  </button>
                  <button class="btn invite-btn p-0" @click="resendInvite(data.item)" v-if="currentIsAdmin == '1'">
                    Resend Invite
                  </button>
                </div>
                <div class="d-flex justify-content-end" @click="toggleEditEventOption(data.item)" v-if="data.item.status == 0 && currentIsAdmin == '1'">
                  <div class="opt edit-btn mb-0">
                    <img src="../../assets/images/ellipsis-h.png" alt=""/>
                    <div class="sub-setting-options edit-event-options" v-if="editEventOptionVisible && selectedItem === data.item">
                      <ul class="d-block pb-0">
                        <li class="cursor-pointer" @click="editInviteRole(data.item)">Edit Role</li>
                        <li class="cursor-pointer" @click="deleteInvite(data.item)">Delete</li>
                      </ul>
                    </div>
                  </div>
                </div>
                  <div v-if="!editEventOptionVisible && selectedItem !== data.item && data.item.status !== 1 && currentIsAdmin !== '1'">
                    <span>N/A</span>
                  </div>
                </template>

            </b-table>
            <!-- <div class="content action-column text-right"  v-if="data.item.status == 0 && currentIsAdmin == '1'">
                  <button
                    class="btn delete-btn p-0 pr-1"
                    @click="deleteInvite(data.item)"
                  ><img src="../../assets/images/trash.png">
                  </button>
                </div> -->
            <content-placeholders :rounded="true" v-if="showLoader1">
              <content-placeholders-heading :img="false" />
            </content-placeholders>
          </div>
        </div>
      </div>
    </b-row>
      <div
          v-if="users.length"
          v-observe-visibility="handleScrolledToButtom"
      ></div>
      <div
      class="no-products-in-shop"
      v-if="(!users || users.length === 0) && showLoader === 0"
    >
      <img src="../../assets/images/no-product.png" alt />
      <p class="header">No users found.</p>
      <p class="info">
        There are no association users to display in the selected category.
      </p>
     
    </div>
    <div
      class="products-list-shim mt-3"
      v-if="users.length == 0 && showLoader == 1"
    >
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>

  </div>
</template>

<script>
// import Swal from "sweetalert2";
import UserInviteModal from "./UserInviteModal.vue";
import UpdateRoleModal from "./Modals/UpdateRoleModal.vue";
import axios from "axios";

export default {
  name: "AssociationUsersDetail",
  data() {
    return {
      associationGuid: "",
      selectedItem: null,
      editEventOptionVisible: false,
      fields: [
        { key: "status", label: "Status", sortable: true },
        {key: "name", label: "Name", sortable: true},
        { key: "target", label: "Email", sortable: true },
        { key: "role", label: "Role", sortable: true },
        { key: "action", label: "Action", class: "text-center" },
      ],
    };
  },
  props: {
    currentIsUmpire: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    associationData: {
      type: Object,
    },
    users: {
      type: Array,
    },
    lastPage: {
      type: Number,
    },
    page: {
      type: Number,
    },
    showLoader: {
      type: Number,
    },
    showLoader1: {
      type: Boolean,
    },
    
  },
  components: {
    UserInviteModal,
    UpdateRoleModal,
  },
  methods: {
    toggleEditEventOption(item) {
      this.selectedItem = item;
      this.editEventOptionVisible = !this.editEventOptionVisible;
    },
    onClickOutside() {
      this.editEventOption = false;
    },
    handleScrolledToButtom(isVisible) {
    if (this.page >= this.lastPage) {
      return;
    }
    if (isVisible) {
      this.$root.$emit("page-increment"); // Emit event to notify parent component
    }
    },
    openUserInviteModal() {
      this.$root.$emit("usersInviteModal");
    },
    editInviteRole(item){
      this.$root.$emit("updateUsersInviteModal", item);
    },
    async cancelInvite(usersData) {
  try {
    await this.$confirm(
      `About cancel the invite to user ${usersData.fname ? usersData.fname : ""} ${usersData.lname ? usersData.lname : ""} (${usersData.target}). Continue?`
    );

    const inviteData = {
      invite_type: usersData.invite_type,
      level: usersData.level,
      association_id: usersData.association_id,
      email: usersData.target,
      invite_id: usersData.id,
    };

   const response = await axios.post(
      `${process.env.VUE_APP_API_URL}association/cancelUserInvite`,
      { inviteData }
    );
    if(response.data.statusCode === 404){
      this.$alert(response.data.message)
      
    this.$root.$emit("userAssociationAction");
      return;
    }

    this.$root.$emit("userAssociationAction");
  } catch (error) {
    console.error("Error cancelling invite:", error);
    // Optionally handle the error or show a notification to the user
  }
},

  async deleteInvite(item) {
    this.$confirm(
      `About to remove user ${item.fname ? item.fname : ""} ${item.lname ? item.lname : ""} (${item.target}) from association. Continue?`
    ).then(() => {
      const formData = new FormData();
      formData.append('association_id', item.association_id);
      formData.append('users_id', item.user_link);
      formData.append('invite_id', item.id);

      axios.post(
        `${process.env.VUE_APP_API_URL}association/deleteUserFromAssociation`,
        formData
      )
      .then(response => {
        if (response.data.statusCode === 200) {
          if (response.data.data.accessAvailable === false) {
            this.$router.push({ name: "Teams" });
          } else {
            this.$root.$emit("userAssociationAction");
            this.$alert(response.data.message);
          }
        }
        else if (response.data.statusCode === 404) {
          this.$alert(response.data.message);
          this.$root.$emit("userAssociationAction");
        } else {
          this.$alert(response.data.message);
        }
      })
      .catch(error => {
        console.error("Error removing user from association", error);
        this.$alert("An error occurred while removing the user from the association.");
      });
    });
  },
  async resendInvite(item) {
  this.$confirm(
    `About to resend the invite to user ${item.fname ? item.fname : ""} ${item.lname ? item.lname : ""} (${item.target}). Continue?`
  ).then(() => {
    let reInviteData = {
      invite_type: item.invite_type,
      level: item.level,
      association_id: item.association_id,
      email: item.target,
      userId: item.user_link,
    };
    
    axios.post(
      `${process.env.VUE_APP_API_URL}association/reInviteUser`,
      { reInviteData: reInviteData }
    )
    .then(response => {
      if (response.data.statusCode === 200) {
        this.$alert(response.data.message);
        this.$root.$emit("userAssociationAction");
      } else if (response.data.statusCode === 404) {
        this.$alert(response.data.message);
        this.$root.$emit("userAssociationAction");
      } else {
        this.$alert("Unexpected error occurred while resending invite.");
      }
    })
    .catch(error => {
      console.error("Error resending invitation", error);
      this.$alert("An error occurred while resending the invitation.");
    });
  });
  }
},

  mounted() { },
}
</script>
<style scoped>
.pending-btn{
    border-radius: 15px;
    background: #A4A4A4;
    color: white;
    text-align: center;
    padding: 2px;
    width: 78px;
}
.accept-btn{
    border-radius: 15px;
    background: #188EF5;
    color: white;
    text-align: center;
    padding: 2px;
    width: 78px;
}
.invite-btn{
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: right;
    color: #188EF5;
}
.delete-btn img{
  height: 20px;
}
.opt {
    height: 30px;
    width: 30px;
}
</style>