<template>
  <div>
    <div class="single-box-score-wrap p-0">
      <div class="single-box-score-left-side w-100">
        <div class="single-score-table single-box-score-wrapper">
          <div class="sec-top" >
            <div class="left-part">
              <h4 class="text-capitalize" v-if="associationData && showLoader == 0">
                {{ associationData.association_name }} - Association Portal
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="products-list-shim" v-if="showLoader == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="3" />
      </content-placeholders>
    </div>
  </div>
</template>
<script>
export default {
  name: "AssociationDetail",

  data() {
    return {};
  },
  props: {
    associationData: {
      type: Object,
    },
    showLoader: {
      type: Number,
    },
   
  },

  methods: {},
  mounted() {
    this.$root.$on("associationRoute", () => {
     this.showLoader = 1;
    });
  },
};
</script>

<style scoped>
.single-box-score-wrap .sub-setting-options {
  left: inherit;
}
.sub-setting-options {
  top: 8%;
  right: 43px;
}
</style>
